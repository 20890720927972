const NotFound = () => {
    return (
        <>
            <div className="conatiner">
                <h1>Oops</h1>
            </div>
        </>
    );
};

export default NotFound;
